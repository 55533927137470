import { ComponentRef, PageRef } from '@wix/editor-platform-sdk-types';

import {
  addSearchResultsHeader,
  getSearchResultsComponent,
  getSearchResultsPage,
  updateSearchResultsPageData,
} from '../searchResults';

import { addSearchBox } from '../searchBox';
import { EditorAppContext } from '../types';
import { Interaction } from '../constants/interaction';
import { SEARCH_RESULTS_PAGE_URL_SLUG } from '../constants';
import { SLOTS } from '../../lib/slots';
import { productWidgetComponentId } from '../constants/componentIds';
import { productsAppDefId } from '../constants/appDefIds';
import { Spec } from '@wix/site-search-common';
import componentConfig from '../../components/SearchResults/.component.json';
import { getSlot } from '../getSlot';

export async function doFirstInstall(appContext: EditorAppContext) {
  const { fedops, editorSDK, appDefinitionId } = appContext;
  fedops.interactionStarted(Interaction.InstallApp);

  const [searchResultsRef, searchResultsPageRef] = await Promise.all([
    getSearchResultsComponent(appContext),
    getSearchResultsPage(appContext),
  ]);

  await editorSDK.pages.navigateTo(appDefinitionId, {
    pageRef: searchResultsPageRef,
  });

  const searchBoxRef = await installAppComponentsConsideringCE(
    appContext,
    searchResultsRef,
    searchResultsPageRef,
  );

  await editorSDK.pages.data.update(appDefinitionId, {
    pageRef: searchResultsPageRef,
    data: {
      pageUriSEO: SEARCH_RESULTS_PAGE_URL_SLUG,
    },
  });

  if (searchBoxRef) {
    await editorSDK.selection.selectComponentByCompRef(appDefinitionId, {
      compsToSelect: [searchBoxRef],
    });
  }

  if (appContext.experiments.enabled('specs.siteSearch.newSuggestionsUX')) {
    await installSearchSuggestionsModal(appContext);
  }

  if (appContext.experiments.enabled(Spec.SearchResultsPageProductsSlot)) {
    await addProductsWidgetPlugin(appContext);
  }

  fedops.interactionEnded(Interaction.InstallApp);
}

async function installAppComponents(
  appContext: EditorAppContext,
  searchResultsRef: ComponentRef | undefined,
  searchResultsPageRef: PageRef,
) {
  const searchBox = await addSearchBox(appContext);

  if (searchResultsRef) {
    await addSearchResultsHeader(appContext, {
      searchResultsPageRef,
      searchResultsRef,
    });
  }

  await updateSearchResultsPageData(appContext, {
    searchResultsPageRef,
  });

  return searchBox;
}

async function installAppComponentsConsideringCE(
  appContext: EditorAppContext,
  searchResultsRef: ComponentRef | undefined,
  searchResultsPageRef: PageRef,
) {
  const { editorSDK, reportError, appDefinitionId } = appContext;
  try {
    return await editorSDK.document.transactions.runAndWaitForApproval(
      appDefinitionId,
      async () => {
        await installAppComponents(
          appContext,
          searchResultsRef,
          searchResultsPageRef,
        );
      },
    );
  } catch (error) {
    reportError(error);
  }
}

const SEARCH_SUGGESTIONS_MODAL_APP_DEF_ID =
  '49a4ef91-9d6e-43ff-94de-88cb5481cdf5';
async function installSearchSuggestionsModal(appContext: EditorAppContext) {
  return appContext.editorSDK.application.add('', {
    appDefinitionId: SEARCH_SUGGESTIONS_MODAL_APP_DEF_ID,
  });
}

export async function addProductsWidgetPlugin(appContext: EditorAppContext) {
  const productsSlot = await getSlot(appContext.editorSDK, SLOTS.products.id);
  await appContext.editorSDK.tpa.widgetPlugins.addWidgetPlugin(
    appContext.appDefinitionId,
    {
      slotCompRef: productsSlot?.compRef!,
      widgetPluginPointer: {
        appDefinitionId: productsAppDefId,
        widgetId: productWidgetComponentId,
      },
    },
  );
}
